define([
    'lodash',
    'experiment',
    'layout/util/layout',
    'layout/specificComponents/balataLayout'
], function (
    _,
    experiment,
    /** layout.layout */layout,
    balataLayout
) {
    'use strict';

    //To Use mediaContainer with background register you component here

    const CONTAINER_POSTFIX = 'container';

    function measureMediaContainer(compType, id, measureMap, nodesMap, structureInfo) {
        if (!_.get(structureInfo, 'designDataItem.background')) {
            return;
        }

        balataLayout.measure(id, measureMap, nodesMap, structureInfo);

        measureMap.width[id] = structureInfo.layout.width || nodesMap[id].clientWidth || measureMap.width[id];

        const isMesh = nodesMap[id].getAttribute('data-is-mesh') === 'true';
        const shouldFixMediaContainerLeft = experiment.isOpen('repeater_anchors_a11y') &&
            !isMesh &&
            compType === 'wysiwyg.viewer.components.MediaContainer';
        if (shouldFixMediaContainerLeft) {
            const repeaterId = nodesMap[id].parentElement.getAttribute('data-repeater-id');
            measureMap.left[id] = nodesMap[id].getBoundingClientRect().left - nodesMap[repeaterId].getBoundingClientRect().left;
        }
    }

    function patchMediaContainer(id, patchers, measureMap, structureInfo, siteData) {
        if (!_.get(structureInfo, 'designDataItem.background')) {
            return;
        }

        balataLayout.patch(id, patchers, measureMap, structureInfo, siteData);
    }

    const registeredComponentTypes = [
        'wysiwyg.viewer.components.MediaBox',
        'wysiwyg.viewer.components.HoverBox',
        'wysiwyg.viewer.components.MediaPlayer',
        'wysiwyg.viewer.components.MediaContainer'
    ];

    _.forEach(registeredComponentTypes, function (compType) {
        layout.registerCustomMeasure(compType, (...args) => measureMediaContainer(compType, ...args));
        layout.registerPureDomHeightMeasure(compType);
        layout.registerRequestToMeasureChildren(compType, balataLayout.BALATA_PATHS_TO_REQUEST_MEASURE.concat([[CONTAINER_POSTFIX]]));
        layout.registerPatcher(compType, patchMediaContainer);
    });

    return {
        measure: measureMediaContainer,
        patch: patchMediaContainer
    };
});
